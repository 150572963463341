@mixin rtl-sass-value($property, $ltr-value, $rtl-value) {
  [dir='ltr'] & {
    #{$property}: $ltr-value;
  }

  [dir='rtl'] & {
    #{$property}: $rtl-value;
  }
}

@mixin rtl-sass-prop($ltr-property, $rtl-property, $value) {
  [dir='ltr'] & {
    #{$ltr-property}: $value;
  }

  [dir='rtl'] & {
    #{$rtl-property}: $value;
  }
}

@mixin rtl-sass-prop-dual($ltr-property, $ltr-value, $rtl-property, $rtl-value) {
  [dir='ltr'] & {
    #{$ltr-property}: $ltr-value;
  }

  [dir='rtl'] & {
    #{$rtl-property}: $rtl-value;
  }
}

@mixin rtl-sass-prop-sd($rtl-property, $rtl-value) {
  [dir='rtl'] & {
    #{$rtl-property}: $rtl-value;
  }
}
